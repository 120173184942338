<template>
  <div>
    <app-datatable
      add-permission="add_user"
      :headers="datatable.headers"
      :end-point="endPoint"
      :table-slots="datatable.tableSlots"
      :reset-table-data="datatable.resetTableData"
      :update-table-data="datatable.updateTableData"
      @data-loaded="handleDataLoaded()"
      @add-new="addNew()"
    >
      <template v-slot:is_active="slotProp">
        <v-switch
          v-model="slotProp.item.is_active"
          dense
          inset
          class="mt-0"
          hide-details
          color="success"
          :ripple="false"
          :disabled="slotProp.item.id === authUserData.id"
          @change="activeDeactive(slotProp.item.id)"
        />
      </template>

      <template v-slot:is_superuser="slotProp">
        <v-switch
          v-model="slotProp.item.is_superuser"
          dense
          color="success"
          inset
          class="mt-0"
          hide-details
          :ripple="false"
          :disabled="slotProp.item.id === authUserData.id"
          @change="changeSuperuser(slotProp.item.id)"
        />
      </template>

      <template v-slot:date_joined="slotProp">
        {{ $_format_date_time(slotProp.item.date_joined) }}
      </template>

      <!-- Actions -->
      <template
        v-if="$_can('change_user')"
        v-slot:actions="slotProp"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdil-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-if="$_can('change_user')"
              @click="updateRow(slotProp.item)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="info"
                  class="fs-18"
                >
                  mdil-pencil
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Edit') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="authUserData.is_superuser"
              @click="getUserPermission(slotProp.item)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="warning"
                  class="fs-18"
                >
                  mdil-shield
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Set permission') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="authUserData.is_superuser"
              @click="resetPassword(slotProp.item)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  class="fs-18"
                >
                  mdil-pencil
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Reset password') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </app-datatable>

    <!------------------- Dialogs ------------------->
    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="datatable.resetTableData = true"
      @update-table-data="datatable.updateTableData = true"
    />

    <confirmation
      :confirm-dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @confirm="deleteRow()"
    />

    <set-permission
      :id="id"
      :set-permission-dialog="setPermissionDialog"
      :user-permissions="userPermissions"
      :all-permissions="formattedAllPermissions"
      :username="username"
      @close="setPermissionDialog = false"
    />

    <reset-password
      :id="id"
      :reset-password-dialog="resetPasswordDialog"
      :username="username"
      @close="resetPasswordDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddEdit from './AddEdit.vue'
import SetPermission from './SetPermission.vue'
import ResetPassword from './ResetPassword.vue'
import axios from 'axios'

export default {
  components: {
    AddEdit,
    SetPermission,
    ResetPassword
  },

  data() {
    return {
      viewDialog: false,
      setPermissionDialog: false,
      resetPasswordDialog: false,
      userPermissions: [],
      allPermissions: [],
      formattedAllPermissions: [],
      username: '',
      addEditDialog: false,
      deleteDialog: false,
      formData: {},
      editMode: false,
      id: 0,
      datatable: {
        resetTableData: false,
        updateTableData: false,
        headers: [
          { text: this.$t('Username'), value: 'username', sortable: true },
          { text: this.$t('Phone'), value: 'first_name', sortable: true },
          { text: this.$t('Active'), value: 'is_active', sortable: true },
          { text: this.$t('Superuser'), value: 'is_superuser', sortable: true },
          { text: this.$t('Created At'), value: 'date_joined', sortable: true },
          { text: this.$t('Actions'), value: 'actions', sortable: false, align: 'center' },
        ],
        tableSlots: [
          'is_active',
          'is_superuser',
          'date_joined',
          'actions',
        ]
      },
      endPoint: 'user/'
    }
  },

  computed: {
    ...mapGetters([
      'authUserData'
    ])
  },

  mounted() {
    this.$_section_title({ title: 'Users', icon: 'mdil-account' })

    // If logged in user is superuser
    // if (this.authUserData.is_superuser) {
    // 	// Show (Active and Superuser) column
    // 	const indexBeforeLastIndex = this.datatable.headers.length - 2
    // 	const active = {
    // 		text: this.$t('Active'),
    // 		value: 'is_active',
    // 		sortable: false
    // 	}
    // 	const superuser = {
    // 		text: this.$t('Superuser'),
    // 		value: 'is_superuser',
    // 		sortable: false
    // 	}
    // 	this.datatable.headers.splice(indexBeforeLastIndex, 0, active)
    // 	this.datatable.headers.splice(indexBeforeLastIndex + 1, 0, superuser)
    // }

    // Get all permissions
    axios.get('user/all_permissions/').then(response => {
      this.allPermissions = []
      this.allPermissions = response.data
    }).then(() => {
      this.formatAllPermissionForTreeView()
    })
  },

  methods: {
    handleDataLoaded() {
      this.datatable.resetTableData = false
      this.datatable.updateTableData = false
    },

    addNew() {
      this.formData = {}
      this.editMode = false
      this.addEditDialog = true
    },

    updateRow(data) {
      this.formData = { ...data }
      this.editMode = true
      this.addEditDialog = true
    },

    formatAllPermissionForTreeView() {
      this.formattedAllPermissions = []

      // Get sections name
      const sectionsName = []
      const alpLen = this.allPermissions.length
      for (let i = 0; i < alpLen; i += 4) {
        if (
          !this.allPermissions[i].name.includes('group') &&
          !this.allPermissions[i].name.includes('permission') &&
          !this.allPermissions[i].name.includes('order detail')
        ) {
          const spaceIndex = this.allPermissions[i].name.indexOf(
            ' ',
            this.allPermissions[i].name.indexOf(' ') + 1
          )
          const name = this.allPermissions[i].name.substr(spaceIndex + 1)

          sectionsName.push(name)
        }
      }

      // Create the tree view array in this format
      // {
      //   id: 0,
      //   name: '',
      //   children: [
      //     {id: 1, name: ''}
      //   ]
      // }
      sectionsName.forEach((sName, index) => {
        this.formattedAllPermissions.push({
          id: 500 + index,
          name: this.$t(sName),
          children: []
        })

        this.allPermissions.forEach(alp => {
          const spaceIndex = alp.name.indexOf(' ', alp.name.indexOf(' ') + 1)
          const name = alp.name.substr(spaceIndex + 1)
          if (name === sName) {
            this.formattedAllPermissions[index].children.push({
              id: alp.id,
              name: this.$t(alp.name)
            })
          }
        })
      })
    },

    getUserPermission(data) {
      this.id = data.id
      axios.get(`user/${data.id}/permissions/`).then(response => {
        this.username = data.username
        this.userPermissions = []
        this.userPermissions = response.data
        this.setPermissionDialog = true
      })
    },

    resetPassword(data) {
      this.id = data.id
      this.username = data.username
      this.resetPasswordDialog = true
    },

    activeDeactive(id) {
      axios.put(`user/${id}/active_user/`, {}).then(response => {
        this.$_notify(response.data)
        this.datatable.updateTableData = true
      })
    },

    changeSuperuser(id) {
      axios.put(`user/${id}/set_admin/`, {}).then(response => {
        this.$_notify(response.data)
        this.datatable.updateTableData = true
      })
    }
  }
}
</script>
