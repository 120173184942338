<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? $t('Update User') : $t('Add New User') }}
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Username')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.username"
                      :label="`* ${$t('Username')}`"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Phone')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.first_name"
                      :label="`* ${$t('Phone')}`"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="!editMode"
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Password')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.password"
                      :append-icon="show1 ? 'mdil-eye' : 'mdil-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      :label="$t('Password')"
                      outlined
                      dense
                      :error-messages="errors"
                      @click:append="show1 = !show1"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="!editMode"
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Confirm Password')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.confirm_password"
                      :append-icon="show2 ? 'mdil-eye' : 'mdil-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      :label="$t('Confirm Password')"
                      outlined
                      dense
                      :error-messages="errors"
                      @click:append="show2 = !show2"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                :disabled="loadingSubmit"
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
                :loading="loadingSubmit"
              >
                <v-icon left>
                  {{ editMode ? 'mdil-check' : 'mdil-plus' }}
                </v-icon>
                <span class="pe-2">
                  {{ editMode ? $t('Save') : $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  data() {
    return {
      loadingSubmit: false,
      show1: false,
      show2: false,
    }
  },

  methods: {
    submit() {
      if (this.formData.password !== this.formData.confirm_password) {
        this.$_notify('Confirm password not match with password', 'error')
        return
      }

      this.loadingSubmit = true

      if (this.editMode) {
        axios.put(`user/${this.formData.id}/`, this.formData).then(() => {
          this.$_notify('Updated successfully')
          this.$emit('update-table-data')
          this.closeDialog()
          this.loadingSubmit = false
        }).finally(() => {
          this.loadingSubmit = false
        })
      } else {
        axios.post('user/', this.formData).then(() => {
          this.$_notify('Added successfully')
          this.$emit('reset-table-data')
          this.closeDialog()
          this.loadingSubmit = false
        }).finally(() => {
          this.loadingSubmit = false
        })
      }
    },

    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped></style>
